<template>
  <div>
    <flixLoader />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      data: false
    }
  },
  methods: {
    getData () {
      var string = this.$route.params.string
      string = atob(string)
      string = JSON.parse(string)
      var d2 = new Date(string.date)
      d2.setMinutes(d2.getMinutes() + 10)
      localStorage.removeItem('flix_orga')
      localStorage.removeItem('flix_login')
      this.$store.commit('setLogout')

      if (new Date().getTime() >= d2.getTime()) {
        return false
      }

      this.$flix_post({
        url: 'user/get_user',
        data: {
          user: string.user
        },
        callback: function (ret) {
          if (ret.data[0]) {
            ret.data[1].md5_id = string.user

            localStorage.setItem('flix_login', btoa(JSON.stringify(ret.data[1])))
            this.$store.commit('setShareEmail', ret.data[1].email)
            this.$store.commit('setUser', ret.data[1])
            window.location.href = string.redirect
          }
        }.bind(this)
      })

      //
      //
    }
  },
  mounted () {
    this.getData()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
